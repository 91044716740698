import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import samLogo from "../sam_logo.png";

const Index = () => {
  return (
    <Container>
      <div className="p-4">
        <h3>Welcome to</h3>
        <h1>Instrumental Coding Challenge</h1>
      </div>
      <div className="p-4">
        <p>
          Click on <Link to="/filers">Filers</Link> to begin.
        </p>
      </div>
      <hr className="bg-light" />
      <div className="p-4">
        <img src={samLogo} alt="Sam Albon" />
      </div>
    </Container>
  );
};

export default Index;
