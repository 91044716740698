import React from "react";
import logo from "../favicon.ico";

const Loading = ({ loading }) => {
  if (!loading) {
    return <span />;
  }

  return (
    <div className="p-4 pulse-wrapper">
      <img className="pulse" src={logo} alt="Sam Albon" />
    </div>
  );
};

export default Loading;
