import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/app.scss";
import GraphQLProvider from "./components/graphqlProvider";
import Header from "./components/header";
import Index from "./routes/index";
import FilersIndex from "./routes/filers/index";
import FilersAwards from "./routes/filers/awards";
import FilersFilings from "./routes/filers/filings";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <GraphQLProvider>
          <Router>
            <Header />
            <Routes>
              <Route exact path="/" element={<Index />} />
              <Route exact path="/filers" element={<FilersIndex />} />
              <Route
                exact
                path="/filers/:id/awards"
                element={<FilersAwards />}
              />
              <Route
                exact
                path="/filers/:id/filings"
                element={<FilersFilings />}
              />
            </Routes>
          </Router>
        </GraphQLProvider>
      </header>
    </div>
  );
}

export default App;
