import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Container, Jumbotron, Row, Col, Card, Button } from "react-bootstrap";
import Loading from "../../components/loading";

const GET_FILERS = gql`
  query GetFilers {
    filers {
      id
      ein
      name
      address
      createdAt
      updatedAt
      awards {
        id
      }
      filings {
        id
      }
    }
  }
`;

const FilerCard = ({ filer }) => {
  const { id, ein, name, address, awards, filings } = filer;

  return (
    <Col xs={4} className="mb-3">
      <Card bg="dark" text="light">
        <Card.Body>
          <Card.Subtitle className="text-muted pb-2">{ein}</Card.Subtitle>
          <Card.Title style={{ minHeight: "3rem" }}>{name}</Card.Title>
          <Card.Text as="small">
            {address.split("\r\n").map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Card.Text>
          <hr className="bg-light" />
          <Card.Text as="div">
            <Row>
              <Col xs={6}>
                <Link as={Button} to={`/filers/${id}/awards`} variant="link">
                  Awards
                </Link>
              </Col>
              <Col xs={6}>{awards.length}</Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Link as={Button} to={`/filers/${id}/filings`} variant="link">
                  Filings
                </Link>
              </Col>
              <Col xs={6}>{filings.length}</Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

const Filers = () => {
  const { loading, error, data } = useQuery(GET_FILERS);

  const filers = useMemo(() => data && data.filers, [data]);

  return (
    <>
      <Jumbotron fluid>
        <Container>
          <h1>Filers</h1>
          <hr />
          <span>
            <b>{filers && filers.length}</b> filers.
          </span>
        </Container>
      </Jumbotron>
      <Container>
        <Loading loading={loading && !data} />
        <div>
          {error && <span>{JSON.stringify(error)}</span>}
          {!loading && !error && filers && (
            <Row>
              {data.filers.map(filer => (
                <FilerCard key={filer.id} filer={filer} />
              ))}
            </Row>
          )}
        </div>
      </Container>
    </>
  );
};

export default Filers;
