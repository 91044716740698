import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Container, Jumbotron, Table } from "react-bootstrap";
import collect from "collect.js";
import NumberFormat from "react-number-format";
import Loading from "../../components/loading";

const GET_AWARDS = gql`
  query GetFilings($id: ID!) {
    filers(id: $id) {
      name
      awards {
        id
        awardAmount
        recipientAddress
        recipientName
      }
    }
  }
`;

const Filings = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_AWARDS, {
    variables: { id }
  });

  const filer = useMemo(() => {
    if (data && data.filers) {
      return data.filers[0];
    }

    return null;
  }, [data]);

  const awards = useMemo(() => {
    if (filer && filer.awards) {
      return filer.awards;
    }

    return null;
  }, [filer]);

  const sumAwardAmount = useMemo(() => {
    if (!awards) {
      return 0;
    }

    return collect(awards).sum("awardAmount");
  }, [awards]);

  return (
    <>
      <Jumbotron fluid>
        <Container>
          <h1>{filer && filer.name}</h1>
          <hr />
          <div>
            <span>
              Awarded <b>{awards && awards.length}</b> times for{" "}
            </span>
            <b>
              <NumberFormat
                value={sumAwardAmount}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </b>
            <span>.</span>
          </div>
        </Container>
      </Jumbotron>
      <Container>
        <Loading loading={loading && !data} />
        <div>
          {error && <span>{JSON.stringify(error)}</span>}
          {!loading && !error && awards && (
            <Table
              className="text-left"
              striped
              responsive
              variant="dark"
              borderless
            >
              <thead>
                <th>Amount</th>
                <th>Name</th>
                <th>Address</th>
              </thead>
              <tbody>
                {awards.map(award => (
                  <tr key={award.id}>
                    <td className="text-right">
                      <NumberFormat
                        value={award.awardAmount}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </td>
                    <td>{award.recipientName}</td>
                    <td>{award.recipientAddress.replace(/\r\n/giu, ", ")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </Container>
    </>
  );
};

export default Filings;
