import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Container, Jumbotron, Table } from "react-bootstrap";
import moment from "moment";
import collect from "collect.js";
import NumberFormat from "react-number-format";
import Loading from "../../components/loading";

const GET_FILINGS = gql`
  query GetFilings($id: ID!) {
    filers(id: $id) {
      name
      filings {
        id
        name
        taxPeriodEnd
        taxPeriodStart
        awards {
          id
          awardAmount
        }
      }
    }
  }
`;

const Filings = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_FILINGS, {
    variables: { id }
  });

  const filer = useMemo(() => {
    if (data && data.filers) {
      return data.filers[0];
    }

    return null;
  }, [data]);

  const filings = useMemo(() => {
    if (filer && filer.filings) {
      return filer.filings;
    }

    return null;
  }, [filer]);

  return (
    <>
      <Jumbotron fluid>
        <Container>
          <h1>{filer && filer.name}</h1>
          <hr />
          <div>
            Filed <b>{filings && filings.length}</b> times.
          </div>
        </Container>
      </Jumbotron>
      <Container>
        <Loading loading={loading && !data} />
        <div>
          {error && <span>{JSON.stringify(error)}</span>}
          {!loading && !error && filings && (
            <Table
              className="text-left"
              striped
              responsive
              variant="dark"
              borderless
            >
              <thead>
                <th>Tax Period</th>
                <th>Awards</th>
                <th>Total Amount Awarded</th>
              </thead>
              <tbody>
                {filings.map(filing => (
                  <tr key={filing.id}>
                    <td>
                      {moment(filing.taxPeriodStart).format("L")}
                      <span className="px-2">to</span>
                      {moment(filing.taxPeriodEnd).format("L")}
                    </td>
                    <td className="text-right">{filing.awards.length}</td>
                    <td className="text-right">
                      <NumberFormat
                        value={collect(filing.awards).sum("awardAmount")}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </Container>
    </>
  );
};

export default Filings;
