import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const GraphQLProvider = ({ children }) => {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_API_ROOT,
    cache: new InMemoryCache()
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
